import React, {
  // createRef,
  memo,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Card } from "clutch/src/Card/Card";
import { Select } from "clutch/src/Select/Select";

import { readState } from "@/__main__/app-state.mjs";
import router, { updateRoute } from "@/__main__/router.mjs";
import RunesStatsCard from "@/game-lol/components/RunesStatsCard.jsx";
import { DEFAULT_COMPARISON_RANK } from "@/game-lol/constants/coaching-constants.mjs";
import LolColors from "@/game-lol/constants/colors.mjs";
import {
  QUEUE_SYMBOLS,
  RANK_SYMBOL_TO_STR,
  RANK_SYMBOLS,
} from "@/game-lol/constants/constants.mjs";
import {
  getPreferredPostmatchComparisonRank,
  updatePreferredPostmatchComparisonRank,
} from "@/game-lol/utils/actions.mjs";
// import { getChampionSubClass } from "@/game-lol/utils/champion-utils.mjs";
import { coachingPerformance } from "@/game-lol/utils/coaching-utils.mjs";
import getRankIcon from "@/game-lol/utils/get-rank-icon.mjs";
import { calcStartOfEndGame } from "@/game-lol/utils/match-utils.mjs";
// import getRoleIcon from "@/game-lol/utils/get-role-icon.mjs";
import Static from "@/game-lol/utils/static.mjs";
import QueueSymbol from "@/game-lol/utils/symbol-queue.mjs";
import { RankSymbol } from "@/game-lol/utils/symbol-rank.mjs";
import {
  coachingStatsKey,
  coachingStatsVariables,
  getStaticData,
  isARAMQueue,
} from "@/game-lol/utils/util.mjs";
import { RankComparison } from "@/shared/Match.style.jsx";
import { OverlayMessage, PerfGrid } from "@/shared/Performance.style.jsx";
import PerformanceCard from "@/shared/PerformanceCard.jsx";
import PerformanceInputStats from "@/shared/PerformanceInputStats.jsx";
import { devLog } from "@/util/dev.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const BASE_CARD_HEIGHT = 390;

function MatchPerformanceCards({ localPlayer, match, timeline }) {
  const { route } = router;
  const {
    lol: { championStatsByMin },
    volatile: { lolCurrentSkinSelection },
  } = useSnapshot(readState);
  const [cardBgImg, setCardBgImg] = useState(null);
  const { t } = useTranslation();
  const cardRefs = useRef([]);

  const matchQueueSymbol = QueueSymbol(match.queueId);
  const isARAM = isARAMQueue(matchQueueSymbol);

  useEffect(() => {
    if (!localPlayer?.championId) return;

    if (
      lolCurrentSkinSelection?.selectedChampionId === localPlayer.championId
    ) {
      setCardBgImg(
        Static.getChampionCenterSkinImage(
          localPlayer.championId,
          lolCurrentSkinSelection?.selectedSkinId,
        ),
      );
    } else {
      setCardBgImg(Static.getChampionCenterSkinImage(localPlayer.championId));
    }
  }, [localPlayer?.championId, lolCurrentSkinSelection]);

  // TODO: potentially remove this after championStatsByMin migration
  const unavailableTiers = [
    RANK_SYMBOLS.platinumPlus,
    RANK_SYMBOLS.emeraldPlus,
    RANK_SYMBOLS.diamondPlus,
    RANK_SYMBOLS.masterPlus,
  ];

  const tiers = Object.getOwnPropertySymbols(RANK_SYMBOL_TO_STR)
    .filter(
      (key) =>
        !RANK_SYMBOL_TO_STR[key]?.hideFilter &&
        unavailableTiers.includes(key) === false,
    )
    .map((key) => {
      const tierData = RANK_SYMBOL_TO_STR[key];
      const Icon = getRankIcon(tierData.key);

      return {
        value: tierData.key,
        text: [tierData.t.name, tierData.t.fallback],
        icon: Icon && <Icon />,
      };
    });
  const preferredComparisonRank = getPreferredPostmatchComparisonRank();
  const [playerRankSymbol, setPlayerRankSymbol] = useState(
    preferredComparisonRank || localPlayer.rank || DEFAULT_COMPARISON_RANK,
  );
  const playerRankDetails = RANK_SYMBOL_TO_STR[playerRankSymbol];
  const playerRankName =
    !isARAM && t(playerRankDetails.t.name, playerRankDetails.t.fallback);
  const playerRankColors = !isARAM && LolColors.ranks?.[playerRankDetails?.key];

  const champions = getStaticData("champions");
  const championKey = champions?.keys?.[localPlayer?.championId];
  const championName = champions?.[championKey]?.name;

  const coachingVars = coachingStatsVariables({
    championId: localPlayer?.championId,
    queue: isARAM ? QUEUE_SYMBOLS.aram : QUEUE_SYMBOLS.rankedSoloDuo,
    tier: !isARAM && playerRankSymbol,
    role: !isARAM && localPlayer?.teamPosition,
  });

  const benchmarkData = championStatsByMin?.[coachingStatsKey(coachingVars)];

  const { loading, tooShort, categories, runesStats } = coachingPerformance({
    localPlayer,
    match,
    timeline,
    benchmarkData,
    queue: matchQueueSymbol,
    lateGameStartTimestamp: calcStartOfEndGame(timeline),
    playerRank: playerRankSymbol,
  });

  // For debugging purposes
  devLog("Postmatch Coaching", categories);

  // const RoleIcon =
  //   player.individualPosition && getRoleIcon(player.individualPosition);

  const comparison = coachingVars.tier ? (
    <RankComparison
      className="span-3"
      style={{ color: playerRankColors?.text }}
    >
      <span className="vs type-form--button">{t("common:vs", "vs")}</span>
      <Select
        selected={playerRankDetails.key}
        options={tiers}
        onChange={(v) => {
          const rankSymbol = RankSymbol(v);
          setPlayerRankSymbol(rankSymbol);
          updatePreferredPostmatchComparisonRank(rankSymbol);
          updateRoute(route.currentPath, route.searchParams, {
            comparisonRank: v,
          });
        }}
      />
      {championName && (
        <span className="type-form--button">{championName}</span>
      )}
    </RankComparison>
  ) : null;

  const cols = categories.length + (runesStats ? 1 : 0);

  if (timeline instanceof Error || benchmarkData instanceof Error) {
    return null;
  }

  if (loading) {
    return (
      <>
        {!isARAM && comparison}
        <PerfGrid className="span-3" style={{ "--cols": 3 }}>
          {categories.map((category) => {
            return (
              <Card
                loading
                key={category.title}
                style={{ height: BASE_CARD_HEIGHT }}
              />
            );
          })}
          {runesStats && <Card loading style={{ height: BASE_CARD_HEIGHT }} />}
        </PerfGrid>
      </>
    );
  }

  if (benchmarkData instanceof Error) {
    return null;
  }

  if (tooShort) {
    return (
      <>
        {comparison}
        <PerfGrid className="span-3" style={{ "--cols": cols }}>
          {categories.map((category) => {
            return (
              <Card
                key={category.title}
                style={{ height: BASE_CARD_HEIGHT, opacity: 0.38 }}
              />
            );
          })}
          {runesStats && (
            <Card style={{ height: BASE_CARD_HEIGHT, opacity: 0.38 }} />
          )}
          <OverlayMessage>
            {t(
              "common:performance.matchTooShort",
              "Match too short to accurately analyze.",
            )}
          </OverlayMessage>
        </PerfGrid>
      </>
    );
  }

  return (
    <>
      {comparison}
      <PerfGrid className="span-3" style={{ "--cols": cols }}>
        {categories.map((category, i) => {
          return (
            <PerformanceCard
              key={category.title}
              ref={cardRefs.current[i]}
              title={category.title}
              score={category.performance.score}
              bgImg={{
                src: cardBgImg,
                onError: (ev) => {
                  if (!localPlayer?.championId) return;
                  const defaultImg = Static.getChampionCenterSkinImage(
                    localPlayer?.championId,
                  );
                  if (ev.target.src !== defaultImg) setCardBgImg(defaultImg);
                },
                className: bgImgStyle(),
              }}
              infoTooltip={t(...[category.explanation])}
              rankColor={playerRankColors?.fill || "var(--shade1)"}
              subContent={
                <PerformanceInputStats
                  rows={category.stats.map((stat) => {
                    const { score, disabled } = stat;

                    return {
                      disabled,
                      label: Array.isArray(stat.title)
                        ? t(...stat.title)
                        : stat.title,
                      value: score.val,
                      format: stat.format,
                      fill: score.score,
                      min: score.min,
                      max: score.max,
                      mean: score.mean,
                      lowerIsBetter: score.lowerIsBetter,
                      tooltipOptions: {
                        description: getTooltipDesc(
                          playerRankName,
                          score,
                          championName,
                          score.lowerIsBetter,
                        ),
                        min: {
                          label: getTooltipLabel(playerRankName)[0],
                          labelColor:
                            (!disabled && playerRankColors?.fill) ||
                            "var(--shade2)",
                        },
                        max: {
                          label: getTooltipLabel(playerRankName)[2],
                          labelColor:
                            (!disabled && playerRankColors?.fill) ||
                            "var(--shade2)",
                        },
                        mean: {
                          label: getTooltipLabel(playerRankName)[1],
                          labelColor:
                            (!disabled && playerRankColors?.fill) ||
                            "var(--shade2)",
                        },
                      },
                    };
                  })}
                />
              }
            />
          );
        })}
        {runesStats && (
          <RunesStatsCard player={localPlayer} maxHeight={BASE_CARD_HEIGHT} />
        )}
      </PerfGrid>
    </>
  );
}

export default memo(MatchPerformanceCards);

function getTooltipLabel(playerRankName) {
  const labels = [
    ["lol:bottom25Percent", "Bottom 25%"],
    ["lol:average", "Average"],
    ["lol:top25Percent", "Top 25%"],
  ];

  if (playerRankName) {
    labels[0] = [
      "lol:rankNameBot25Percent",
      "{{rankName}} Bot 25%",
      {
        rankName: playerRankName,
      },
    ];
    labels[1] = [
      "lol:rankNameAvg",
      "{{rankName}} Avg",
      {
        rankName: playerRankName,
      },
    ];
    labels[2] = [
      "lol:rankNameTop25Percent",
      "{{rankName}} Top 25%",
      {
        rankName: playerRankName,
      },
    ];
  }

  return labels;
}

function getTooltipDesc(playerRankName, score, championName, lowerIsBetter) {
  const options =
    score.val < score.mean
      ? {
          amount: (1 - score.val / score.mean).toLocaleString(getLocale(), {
            style: "percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          rank: playerRankName,
          character: championName,
        }
      : {
          amount: (score.val / score.mean - 1).toLocaleString(getLocale(), {
            style: "percent",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          rank: playerRankName,
          character: championName,
        };

  if (!playerRankName) {
    return score.val < score.mean && !lowerIsBetter
      ? [
          "common:coaching.youDidAmountWorseThanAverageCharacter",
          "You did {{amount}} worse than the average {{character}}",
          options,
        ]
      : [
          "common:coaching.youDidAmountBetterThanAverageCharacter",
          "You did {{amount}} better than the average {{character}}",
          options,
        ];
  }

  return score.val < score.mean && !lowerIsBetter
    ? [
        "common:coaching.youDidAmountWorseThanAverageRankCharacter",
        "You did {{amount}} worse than the average {{rank}} {{character}}",
        options,
      ]
    : [
        "common:coaching.youDidAmountBetterThanAverageRankCharacter",
        "You did {{amount}} better than the average {{rank}} {{character}}",
        options,
      ];
}

const bgImgStyle = () => css`
  & {
    position: absolute;
    top: 0;
    left: 50%;
    translate: -32% -13%;
    -webkit-mask-image: radial-gradient(
      circle at top center,
      hsl(0deg 0% 0% / 39%),
      transparent 80%
    );
    width: 200%;
  }
`;
